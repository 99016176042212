import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./app/components/Login";
import Register from "./app/components/Register";
import Home from "./app/components/Home";
import Profile from "./app/components/Profile";
import BoardUser from "./app/components/BoardUser";
import BoardModerator from "./app/components/BoardModerator";
import BoardAdmin from "./app/components/BoardAdmin";
import CapturePage from "./app/components/Capture";
import UploadPage from "./app/components/UploadPage";

import { logout } from "./app/slices/auth";
import EventBus from "./app/common/EventBus";
import Sidebar from "./app/components/Sidebar";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [letterData, setLetterData] = useState([]);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const fetchLetterData = useCallback(async () => {
    try {
      const response = await fetch('https://api.fxdc-post.com/admin/getletterdata');
      if (!response.ok) throw new Error('Failed to fetch letter data');
      const data = await response.json();
      setLetterData(data.data);
    } catch (error) {
      console.error('Error fetching letter data:', error);
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
      fetchLetterData();
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", logOut);

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut, fetchLetterData]);

  return (
    <Router>
      <div className="d-flex">
        {/* Top Navbar */}
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            Awoki N.V
          </Link>
          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={logOut}>
                  LogOut
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
        </nav>

        {/* Sidebar */}
        {currentUser && (
          <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
            <Sidebar
              showModeratorBoard={showModeratorBoard}
              showAdminBoard={showAdminBoard}
              isCollapsed={isCollapsed}
              toggleCollapse={() => setIsCollapsed(!isCollapsed)}
            />
          </div>
        )}

        {/* Main content */}
        <div
          className="main-content"
          style={{
            marginLeft: isCollapsed ? "4rem" : "7rem",
          }}
        >
          {!currentUser ? (
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route 
                path="/capture" 
                element={<CapturePage letterData={letterData} />}
              />
              <Route path="/upload" element={<UploadPage />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/user" element={<BoardUser />} />
              <Route path="/mod" element={<BoardModerator />} />
              <Route path="/admin" element={<BoardAdmin />} />
            </Routes>
          )}
        </div>
      </div>
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import CustomWebcam from './CustomWebcam';
import {
  TextInput,
  Text,
  Container,
  Card,
  Stack,
  Group,
  Image,
  UnstyledButton
} from '@mantine/core';
import { IconPencil, IconSearch, IconMaximize, IconMinimize } from '@tabler/icons-react';
import './CapturePage.css';

const CapturePage = ({ letterData }) => {
  const [ocrResult, setOcrResult] = useState('');
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [formValues, setFormValues] = useState({
    EILANDENCARIBISCHNEDERLAND: '',
    WOONADRES: '',
    District: '',
    vak: '',
    Volgorde: ''
  });
  const [isEditable, setIsEditable] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userData, setUserData] = useState(null);
  const [ocrCompleted, setOcrCompleted] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const OCR_BASE_URI = "https://model.fxdc-post.com";
  const BASE_URI = "https://api.fxdc-post.com";

  useEffect(() => {
    const storedUserData = localStorage.getItem('user');
    if (storedUserData) {
      try {
        setUserData(JSON.parse(storedUserData));
      } catch (error) {
        console.error('Error parsing user data:', error);
      }
    }
  }, []);

  const searchInLocalData = (ocrText) => {
    const lines = ocrText.split('\n')
      .filter(line => line.trim() !== '')
      .map(line => line.trim());

    for (const line of lines) {
      const match = letterData.find(letter => 
        letter.WOONADRES.toLowerCase().includes(line.toLowerCase())
      );

      if (match) {
        setSearchResults([match]);
        return;
      }
    }
    
    alert('No matching address found');
    setSearchResults([]);
  };

  const handleImageCapture = async (capturedImage) => {
    try {
      const formData = new FormData();
      formData.append('image', capturedImage, 'image.jpg');

      // Call the new OCR endpoint
      const response = await fetch(`${OCR_BASE_URI}/ocr/`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('OCR request failed');
      }

      const result = await response.json();
      
      if (!result.success) {
        throw new Error(result.error || 'OCR processing failed');
      }

      // Set OCR result and image preview
      setOcrResult(result.ocr_result);
      const imageUrl = URL.createObjectURL(capturedImage);
      setCroppedImageUrl(imageUrl);
      
      setOcrCompleted(true);
      setIsSubmitted(false);
      setSearchResults([]);
      setIsFullscreen(false);

      // Search in local data
      searchInLocalData(result.ocr_result);

    } catch (error) {
      setIsFullscreen(false);
      console.error('Error processing image:', error);
      alert('The picture does not meet the requirements');
    }
  };

  // Cleanup function for object URLs
  useEffect(() => {
    return () => {
      if (croppedImageUrl) {
        URL.revokeObjectURL(croppedImageUrl);
      }
    };
  }, [croppedImageUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    const requiredFields = ['street', 'houseNumber', 'route', 'area', 'code'];
    const missingFields = requiredFields.filter(field => !formValues[field]);

    if (missingFields.length > 0) {
      alert(`Please fill in all fields: ${missingFields.join(', ')}`);
      return;
    }

    try {
      const response = await fetch(`${BASE_URI}/admin/createletter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          EILANDENCARIBISCHNEDERLAND: formValues.EILANDENCARIBISCHNEDERLAND,
          WOONADRES: formValues.WOONADRES,
          District: formValues.District,
          vak: formValues.vak,
          Volgorde: formValues.Volgorde,
          uploadedBy: userData?.email || "anonymous"
        }),
      });

      if (!response.ok) throw new Error('Submission failed');

      setIsSubmitted(true);
      setFormValues({
        EILANDENCARIBISCHNEDERLAND: '',
        WOONADRES: '',
        District: '',
        vak: '',
        Volgorde: ''
      });
    } catch (error) {
      console.error('Submission Error:', error);
      alert('Failed to submit data. Please try again.');
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleRetrySearch = () => {
    searchInLocalData(ocrResult);
  };

  return (
    <Container
      size="xs"
      className="capture-container"
      style={{
        height: '100vh',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%'
      }}
    >
      <Card
        className="capture-card"
        shadow="sm"
        padding="lg"
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        <Stack
          gap="md"
          style={{
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden',
            paddingRight: '10px'
          }}
        >
        <div style={{ position: 'relative' }}>
            <CustomWebcam 
              onImageCapture={handleImageCapture} 
              isFullscreen={isFullscreen}
              setIsFullscreen={setIsFullscreen}
              hidePreview={!!ocrResult}
              style={{
                height: isFullscreen ? '100vh' : 'auto',
                width: '100%'
              }}
            />
            <UnstyledButton
              onClick={toggleFullscreen}
              style={{
                position: 'absolute',
                bottom: '1rem',
                right: '1rem',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                padding: '0.5rem',
                borderRadius: '50%',
                cursor: 'pointer',
                zIndex: 1001
              }}
            >
              {isFullscreen ? (
                <IconMinimize size={24} stroke={1.5} />
              ) : (
                <IconMaximize size={24} stroke={1.5} />
              )}
            </UnstyledButton>
          </div>

          {!isFullscreen && (
            <>
              {croppedImageUrl && (
                <Image
                  src={croppedImageUrl}
                  alt="Processed"
                  className="captured-image"
                />
              )}

              {ocrResult && (
                <div style={{ paddingBottom: '2rem', paddingTop: '2rem' }}>
                  <Group justify="space-between" align="center" mb="xs">
                    <Text fw={500}>OCR Result:</Text>
                    <div style={{ paddingBottom: '2rem', width: '100%' }}>
                      {isEditable ? (
                        <TextInput
                          value={ocrResult}
                          onChange={(e) => setOcrResult(e.target.value)}
                          variant="filled"
                          placeholder="OCR Result"
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <Text>{ocrResult || 'No text detected'}</Text>
                      )}
                    </div>
                    <Group gap="xs">
                      <UnstyledButton
                        className={`ocr-action-button ${isEditable ? 'edit-button' : ''}`}
                        onClick={() => setIsEditable(!isEditable)}
                      >
                        <Group gap={6} align="center">
                          <IconPencil size={16} stroke={1.5} />
                          <Text size="sm">{isEditable ? 'Save' : 'Edit'}</Text>
                        </Group>
                      </UnstyledButton>

                      <UnstyledButton
                        className="ocr-action-button retry-search-button"
                        onClick={handleRetrySearch}
                      >
                        <Group gap={6} align="center">
                          <IconSearch size={16} stroke={1.5} />
                          <Text size="sm">Retry Search</Text>
                        </Group>
                      </UnstyledButton>
                    </Group>
                  </Group>
                </div>
              )}

              {ocrCompleted && searchResults.length === 0 && (
                <Stack>
                  <TextInput
                    label="EILANDENCARIBISCHNEDERLAND"
                    name="street"
                    value={formValues.EILANDENCARIBISCHNEDERLAND}
                    onChange={handleInputChange}
                    variant="filled"
                    required
                  />
                  <TextInput
                    label="WOONADRES"
                    name="houseNumber"
                    value={formValues.WOONADRES}
                    onChange={handleInputChange}
                    variant="filled"
                    required
                  />
                  <TextInput
                    label="District"
                    name="route"
                    value={formValues.District}
                    onChange={handleInputChange}
                    variant="filled"
                    required
                  />
                  <TextInput
                    label="Vak"
                    name="area"
                    value={formValues.vak}
                    onChange={handleInputChange}
                    variant="filled"
                    required
                  />
                  <TextInput
                    label="Volgorde"
                    name="code"
                    value={formValues.Volgorde}
                    onChange={handleInputChange}
                    variant="filled"
                    required
                  />
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', marginBottom: '4rem' }}>
                    <button
                      className="submit-button"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </Stack>
              )}

              {searchResults.length > 0 && (
                <div style={{ marginBottom: '5rem' }}>
                  <Card withBorder>
                    <Text fw={500} mb="xs">Search Result:</Text>
                    <Text size="xl" fw={700} style={{ fontSize: '2rem', textAlign: 'center' }}>
                      Sequence: {searchResults[0].District}.
                      {searchResults[0].vak}.
                      {searchResults[0].Volgorde}
                    </Text>
                  </Card>
                </div>
              )}

              {isSubmitted && (
                <Text c="green" ta="center" fw={500}>
                  Submitted Successfully!
                </Text>
              )}
            </>
          )}
        </Stack>
      </Card>
    </Container>
  );
};

export default CapturePage;